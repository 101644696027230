import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import axios from "axios";
import { postLogin } from "../../Utils/Endpoints"; // Certifique-se de que esse caminho está correto

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevenir o envio padrão do formulário

    if (username && password) {
      try {
        const response = await axios.post(postLogin, {
          username: username,
          password: password,
        });

        alert("Usuário logado com sucesso!");
        console.log(response.data);
        navigate("/home");
      } catch (error) {
        console.error("Erro ao logar o usuário", error);
        alert("Erro ao logar o usuário. Tente novamente.");
      }
    } else {
      alert("Por favor, preencha todos os campos.");
    }
  };

  const handleRegister = () => {
    navigate("/register");
  };

  return (
    <div className="mainDiv">
      <div className="divHeader">
        {/* Logo placeholder */}
        <img src="logo.png" alt="Logo" className="logo" />
        <p className="titleHeader">Qweerp</p>
        <p className="subtitleHeader">Inusitado, Espontâneo, Real</p>
      </div>
      <div className="formLogin">
        <p className="subtitleHeader">Entre para continuar.</p>
        <form className="form" onSubmit={handleLogin}>
          <input
            className="input"
            type="text"
            placeholder="Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            className="input"
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <div className="buttonContainer">
            <button className="button" type="submit">
              Entrar
            </button>
          </div>
        </form>

        <p className="textForgotPassword">Esqueceu sua senha? Clique Aqui</p>

        <div className="buttonContainer">
          <button
            className="buttonRegister"
            type="button"
            onClick={handleRegister}
          >
            Registrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
