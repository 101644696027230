import React from "react";
import "./style.css"; // Você pode criar um arquivo CSS separado para estilizar a página.

const Home: React.FC = () => {
  return (
    <div className="homeContainer">
      {/* Barra lateral esquerda */}
      <aside className="sidebar">
        <img src="logo.png" alt="Logo" className="sidebarLogo" />
        <nav className="sidebarNav">
          <ul>
            <li>
              <a href="/">Início</a>
            </li>
            <li>
              <a href="/explore">Explorar</a>
            </li>
            <li>
              <a href="/notifications">Notificações</a>
            </li>
            <li>
              <a href="/messages">Mensagens</a>
            </li>
            <li>
              <a href="/profile">Perfil</a>
            </li>
            <li>
              <a href="/settings">Configurações</a>
            </li>
          </ul>
        </nav>
        <button className="button tweetButton">Postar</button>
      </aside>

      {/* Seção principal */}
      <main className="feed">
        <h1>Início</h1>
        <div className="postBox">
          <textarea
            className="postInput"
            placeholder="O que está acontecendo?"
          ></textarea>
          <button className="button postButton">Postar</button>
        </div>

        {/* Exemplo de feed de postagens */}
        <div className="post">
          <div className="postHeader">
            <strong>Usuário 1</strong> <span>@usuario1</span>
          </div>
          <p>Essa é uma postagem de exemplo.</p>
        </div>
        <div className="post">
          <div className="postHeader">
            <strong>Usuário 2</strong> <span>@usuario2</span>
          </div>
          <p>Mais uma postagem de exemplo.</p>
        </div>
      </main>

      {/* Barra lateral direita */}
      <aside className="sidebarRight">
        <h2>Tendências</h2>
        <ul>
          <li>
            <strong>#Exemplo1</strong> 12K Tweets
          </li>
          <li>
            <strong>#Exemplo2</strong> 8K Tweets
          </li>
          <li>
            <strong>#Exemplo3</strong> 5K Tweets
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Home;
