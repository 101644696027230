import React, { useState } from "react";
import axios from "axios";
import "./style.css";
import { postRegisterUser } from "../../Utils/Endpoints";

const Register: React.FC = () => {
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Verifique se as senhas coincidem
    if (password !== confirmPassword) {
      alert("As senhas não coincidem.");
      return;
    }

    try {
      await axios.post(`${postRegisterUser}`, {
        username: username,
        name: name,
        email: email,
        password: password,
      });
      alert("Usuário registrado com sucesso!");
    } catch (error) {
      console.error("Erro ao registrar o usuário", error);
      alert("Erro ao registrar o usuário. Tente novamente.");
    }
  };

  return (
    <div className="mainDiv">
      <div className="divHeader">
        <img src="logo.png" alt="Logo" className="logo" />
        <p className="titleHeader">Qweerp</p>
        <p className="subtitleHeader">Inusitado, Espontâneo, Real</p>
      </div>
      <div className="formRegister">
        <p className="subtitleHeader">Cadastre-se agora! É de graça!</p>
        <form className="form" onSubmit={handleSubmit}>
          <input
            className="input"
            type="text"
            placeholder="Nome de Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            className="input"
            type="text"
            placeholder="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            className="input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            className="input"
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            className="input"
            type="password"
            placeholder="Confirme a Senha"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button className="button" type="submit">
            Registrar
          </button>
        </form>

        <p className="textAlreadyRegistered">
          Já possui uma conta? <a href="/">Faça login</a>
        </p>
      </div>
    </div>
  );
};

export default Register;
